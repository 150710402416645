<template lang="pug">
  div.page-statistics
    loading(:active.sync="busy", :is-full-page="true")
    .widget(v-if="filters")
      .widget-header
        h1.title Conversions
        i.la.la-2x.la-filter.d-flex.align-items-center.mobile(@click="areFiltersOpened = !areFiltersOpened")
      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'conversions'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            ov-date-time-picker(v-model="filters.datePicker")
            select-advertiser(v-model="filters.advertiser_id")
            select-offer(v-model="filters.offer_id")
            select-publisher(v-model="filters.publisher_id")
            select-placement(v-model="filters.placement_id")
            .form-group
              label Status
              //multiselect(:options="statusOptions", v-model="filters.report_status",
                track-by="v", label="t", deselect-label="", select-label="")
              select.form-control(v-model="filters.report_status")
                option(value="") All
                option(value="reported") Reported only
                option(value="not_reported") Not reported
                option(value="rejected") Rejected

            .form-group
              label Additional Parameters
              input.form-control(type="text", v-model="filters.add_parameters")
            .form-group
              label Adv.Parameters
              input.form-control(type="text", v-model="filters.adv_parameters")
            .form-group
              label Additional Headers
              input.form-control(type="text", v-model="filters.add_headers")
            click-list(v-model="filters.clicks", label="Clicks")
          .form-row-end.desktop
            .form-group
              label &nbsp;
              // button.btn.btn-primary(type="submit") Go
              b-button-group
                b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                  b-dropdown-item(@click="loadData(true)")
                    i.la.la-download
                    | Export

          .w-100.mobile.p-2.border-top.position-sticky.bg-white(style="bottom: 0; z-index: 2;")
            b-button-group.submit-filters-button
              b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                b-dropdown-item(@click="loadData(1)")
                  i.la.la-download.mr-1
                  | Export

        p.clearfix &nbsp;

        paginate(:paginator="paginate", @update-page-size="loadData")

        .ov-table-wrapper
          .tabs.table-tabs
            ul.nav.nav-tabs
              li.nav-item
                a.nav-link(:class="{active:selectedTab==='search'}", @click="selectedTab='search'") Search ({{ paginate.total }})
              li.nav-item
                a.nav-link(:class="{active:selectedTab==='bucket', highlight: bucket.length > 0}", @click="selectedTab='bucket'") Selected
                  span.bucket-size ({{bucket.length}})
          table.table.table-bordered.table-condensed.min-height
            thead
              tr
                th
                  span
                    a(href="javascript:void(0);", @click="selectAll()")
                      i.la.la-check-square-o
                    =' | '
                  span
                    a(href="javascript:void(0);", @click="clearBucket()")
                      i.la.la-square-o
                th
                  .text ID
                th
                  .text P
                th
                  .text R
                th
                  .text Advertiser
                th
                  .text Offer
                th
                  .text.has-buttons Placement
                    a.toggle-mode(href="javascript:void(0);", @click="togglePlacementViewMode()")
                      i.la.la-arrows-h
                th
                  .text Publisher
                th
                  .text Subsource
                th
                  .text Appname / Bundle
                th
                  .text Type
                th
                  .text.text-revenue.text-center Rev.
                th
                  .text.text-cost.text-center Cost
                th
                  .text.text-profit.text-center Prof.
                th
                  .text ClickID
                th
                  .text ClickIP
                th
                  .text Cl.Time
                th
                  .text Cv.Time
                th
                  .text ReportTime
                th
                  .text CTIT
                th
                  .text Status
                th(v-for="c in additionalColumns")
                  .text {{ c.type }}:{{ c.name }}
                th
                  .text &nbsp;
                th
                  b-dropdown(text="Actions", size="sm", variant="link")
                    // b-dropdown-item(href="#", @click="bulkReport()")
                      i.la.la-bell.mr-2
                      | Report to Publisher
                    // b-dropdown-item(href="#", @click="bulkUnreport()")
                      i.la.la-bell-slash.mr-2
                      | Undo Report to Publisher
                    b-dropdown-item(href="#", @click="bulkReject()")
                      i.la.la-undo.mr-2
                      | Reject
                    b-dropdown-item(href="#", @click="bulkUnconvert()")
                      i.la.la-trash.mr-2
                      | Cancel
            tbody
              tr(v-if="records.length === 0")
                td(colspan="23") No matching records were found
              //tr(v-for="r in records", v-bind:key="r.id")
              tr(v-for="r in (selectedTab==='bucket'?bucket:records)", v-bind:key="r.id",
                :class="{'row-selected': bucketHash[r.id], 'row-danger': r.rejected}")
                td.col-select(:class="{'bucket-row':bucketHash[r.id]}")
                  a(href="javascript:void(0)", @click="toggleBucket(r)")
                    i.la(:class="{'la-check-square':bucketHash[r.id], 'la-stop':!bucketHash[r.id]}")
                td {{ r.id }}
                td {{ r.click && r.click.advanced_privacy ? 'P' : '' }}
                td {{ r.click && r.click.is_reporting ? 'R' : '' }}
                td
                  span(v-if="r.advertiser")
                    entity(type="advertiser", :id="r.advertiser.id", :name.sync="r.advertiser.name", @click="appendEntity(r,'advertiser')")
                td
                  span(v-if="r.offer")
                    entity(type="offer", :id="r.offer.id", :name.sync="r.offer.name", @click="appendEntity(r,'offer')")
                td
                  span(v-if="r.placement")
                    entity(type="placement", :id="r.placement.id", :name.sync="r.placement.name", :mode="placementViewMode", @click="appendEntity(r,'placement')", :data="r.placement")
                td
                  span(v-if="r.publisher")
                    entity(type="publisher", :id="r.publisher.id", :name.sync="r.publisher.name", @click="appendEntity(r,'publisher')")
                td
                  entity(v-if="r.click.sub1", type="sub1", :id="r.click.sub1.id", :name.sync="r.click.sub1.name")
                  entity(v-if="r.click.sub2", type="sub2", :id="r.click.sub2.id", :name.sync="r.click.sub2.name")
                td
                  entity(v-if="r.click.appname", type="appname", :id="r.click.appname.id", :name.sync="r.click.appname.name")
                  =' / '
                  entity(v-if="r.click.bundle", type="bundle", :id="r.click.bundle.id", :name.sync="r.click.bundle.name")
                td {{ r.click.request_type === 1 ? 'Impression' : 'Click' }}
                //td
                //div.adv-info(v-if="r.click && r.click.adv_params")
                //	div.adv-appname {{ r.click.adv_params.appname }}
                //	div.adv-bundle {{ r.click.adv_params.bundle }}
                //	div.adv-subsource {{ r.click.adv_params.sub1 }}
                td.text-center.text-revenue {{ r.revenue }}
                td.text-center.text-cost {{ r.cost }}
                td.text-center.text-profit {{ r.profit }}
                td(style="max-width: 220px")
                  router-link.btn-edit(v-if="r.click", v-b-tooltip.hover.bottom, title="View",
                    :to="{name:'click-view', params: {id: r.click.id}}") {{ r.click.id }}
                  div.text-muted(v-if="r.click")
                    small.text-muted(style="word-break: break-all;") {{ r.click.aff_clickid }}
                  div(v-if="r.remote_id") AF ID: {{ r.remote_id }}
                td
                  span(v-if="r.click") {{ r.click.ip_fmt }}
                td
                  span(v-if="r.click") {{ r.click.created_at }}
                td {{ r.created_at }}
                td {{ r.report_time }}
                td {{ r.ctit_fmt }}
                td
                  //span.badge.badge-secondary(v-if="r.status==='new'") New
                  span.badge.badge-danger(v-if="r.rejected") Rejected
                  span.badge.badge-success(v-else-if="r.reported") Reported
                  //span.badge.badge-danger(v-if="r.status==='cancelled'") Cancelled
                  div {{ r.report_reason }}
                td(v-for="c in additionalColumns")
                  span(v-if="r.click")
                    span(v-if="r.click.headers && c.type==='h'") {{ r.click.headers[c.name] }}
                    span(v-if="r.click.params && c.type==='p'") {{ r.click.params[c.name] }}
                    span(v-if="r.click.advertiser_params && c.type==='a'") {{ r.click.advertiser_params[c.name] }}
                td.info-buttons
                  a.btn(href="javascript:void(0)", :id="`pop-conversion-${r.id}`", v-b-tooltip.hover.bottom, title="Conversion" )
                    i.la.la-info-circle
                  b-popover.params-popover(:target="`pop-conversion-${r.id}`", placement="left", title="Conversion Params")
                    pre.text-small {{ r.params }}

                  a.btn(href="javascript:void(0)", :id="`pop-click-${r.id}`",  v-b-tooltip.hover.bottom, title="Click")
                    i.la.la-info-circle
                  b-popover.params-popover(:target="`pop-click-${r.id}`", placement="left", title="Click Params")
                    pre.text-small {{ r.click.params }}
                td.actions
                  // button.btn.btn-primary(type="button", v-b-tooltip.hover.bottom, title="Report", @click="report(r)", v-if="USER.permissions['conversions::REPORT'] && !r.reported")
                    i.la.la-bell
                  // button.btn.btn-secondary(type="button", v-b-tooltip.hover.bottom, title="Undo Report", @click="unreport(r)", v-if="USER.permissions['conversions::REPORT'] && r.reported")
                    i.la.la-bell-slash
                  button.btn.btn-secondary(type="button", v-b-tooltip.hover.bottom, title="Reject", @click="reject(r)", v-if="USER.permissions['conversions::CANCEL'] && !r.rejected")
                    i.la.la-undo
                  button.btn.btn-secondary(type="button", v-b-tooltip.hover.bottom, title="Convert", @click="reconvert(r)", v-if="USER.permissions['conversions::CANCEL'] && r.rejected")
                    i.la.la-redo
                  button.btn.btn-danger(type="button", v-b-tooltip.hover.bottom, title="Cancel", @click="unconvert(r)", v-if="USER.permissions['conversions::CANCEL'] && !r.rejected")
                    i.la.la-trash


        paginate(:paginator="paginate", @update-page-size="loadData")


</template>
<style lang="scss">
.params-popover {
  max-width: 350px;
}

.info-buttons {
  a.btn {
    font-size: 16px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0;
    text-align: center;
    border-radius: 100%;
  }
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'Conversions',
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
  async beforeRouteEnter(to, from, next) {
    let dateStartQ = to.query.date_start;

    let dateStartObj = null;
    if (dateStartQ) {
      dateStartObj = moment(dateStartQ, 'YYYY-MM-DD HH:mm');
    }
    if (!dateStartObj || !dateStartObj.isValid()) {
      dateStartObj = moment(
        moment()
          .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
          .format('DD/MM/YYYY') + ' 00:00',
        'DD/MM/YYYY HH:mm',
      );
    }
    let dateEndQ = to.query.date_end;

    let dateEndObj = null;
    if (dateEndQ) {
      dateEndObj = moment(dateEndQ, 'YYYY-MM-DD HH:mm');
    }
    if (!dateEndObj || !dateEndObj.isValid()) {
      dateEndObj = moment(
        moment()
          .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
          .format('DD/MM/YYYY') + ' 23:59',
        'DD/MM/YYYY HH:mm',
      );
    }

    let selectedAdvertiserOptions = await Vue.ovData.advertiser.getSelectedOptions(
      to.query.advertiser_id,
    );
    let selectedPublisherOptions = await Vue.ovData.publisher.getSelectedOptions(
      to.query.publisher_id,
    );
    let selectedOfferOptions = await Vue.ovData.offer.getSelectedOptions(to.query.offer_id);
    let selectedPlacementOptions = await Vue.ovData.placement.getSelectedOptions(
      to.query.placement_id,
    );

    next(async (vm) => {
      vm.filters.offer_id = selectedOfferOptions;
      vm.filters.publisher_id = selectedPublisherOptions;
      vm.filters.placement_id = selectedPlacementOptions;
      vm.filters.advertiser_id = selectedAdvertiserOptions;
      vm.filters.datePicker.dateRange.startDate = dateStartObj;
      vm.filters.datePicker.dateRange.endDate = dateEndObj;

      vm.loadData();
      return vm;
    });
  },
  data() {
    const defaultFilters = {
      keyword: '',
      clicks: '',
      add_parameters: '',
      adv_parameters: '',
      add_headers: 'x-requested-with',
      report_status: '',
      daily: false,
      offer_id: [],
      placement_id: [],
      publisher_id: [],
      advertiser_id: [],
      datePicker: {
        timezone: 0,
        dateRange: {
          startDate: null,
          endDate: null,
        },
      },
    };
    return {
      areFiltersOpened: false,
      additionalColumns: [],
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 30,
        onPageChange: () => {
          this.loadData();
        },
      },
      records: [],
      placementViewMode: 'mini',
      selectedTab: 'search',
      bucket: [],
      bucketHash: {},
      defaultFilters,
      filters: { ...defaultFilters },
      sortBy: {
        field: 'id',
        direction: 'desc',
        sort: null,
      },
      // statusOptions: [
      // 	{v: 'new', t: 'Not Reported'},
      // 	{v: 'reported', t: 'Reported'},
      // 	{v: 'rejected', t: 'Rejected'},
      // ],
    };
  },
  methods: {
    // async bulkReport(){
    // 	if (this.busy){
    // 		return;
    // 	}
    // 	let recordIds = this.bucket.filter(o => !o.reported).map(o => o.id);
    // 	if (this.bucket.length === 0 || !recordIds.length) {
    // 		return this.$ovNotify.error('No unreported conversions were selected');
    // 	}
    //
    // 	this.busy = true;
    // 	try {
    // 		await this.$ovReq.post('conversion/bulkReport', { ids: recordIds });
    // 		this.busy = false;
    // 		await this.loadData();
    // 	} catch (e){
    // 		console.error(e);
    // 	}
    // 	this.busy = false;
    // },

    async bulkReject() {
      if (this.busy) {
        return;
      }
      let recordIds = this.bucket.filter((o) => !o.rejected).map((o) => o.id);
      if (this.bucket.length === 0 || !recordIds.length) {
        return this.$ovNotify.error('No unrejected conversions were selected');
      }

      let confirmed = await this.$bvModal.msgBoxConfirm(
        'This will deduct all revenue & cost of these conversions. Are you sure you want to proceed?',
      );
      if (!confirmed) {
        return;
      }
      this.busy = true;
      try {
        await this.$ovReq.post('conversion/bulkReject', { ids: recordIds });
        this.busy = false;
        await this.loadData();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async bulkUnconvert() {
      if (this.busy) {
        return;
      }
      let recordIds = this.bucket.map((o) => o.id);
      if (this.bucket.length === 0 || !recordIds.length) {
        return this.$ovNotify.error('No conversions were selected');
      }

      this.busy = true;
      try {
        await this.$ovReq.post('conversion/bulkUnconvert', { ids: recordIds });
        this.busy = false;
        await this.loadData();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    clearBucket() {
      this.bucket = [];
      this.bucketHash = {};
    },
    selectAll() {
      this.records.forEach((r) => {
        if (!this.bucketHash[r.id]) {
          Vue.set(this.bucketHash, r.id, true);
          this.bucket.push(r);
        }
      });
    },
    toggleBucket(r) {
      if (this.bucketHash[r.id]) {
        // delete this.bucketHash[r.id];
        Vue.set(this.bucketHash, r.id, false);
        for (let i = 0; i < this.bucket.length; i++) {
          if (this.bucket[i].id === r.id) {
            this.bucket.splice(i, 1);
            return;
          }
        }
      } else {
        // this.bucketHash[r.id] = true;
        Vue.set(this.bucketHash, r.id, true);
        this.bucket.push(r);
      }
    },
    togglePlacementViewMode() {
      if (this.placementViewMode === 'mini') {
        this.placementViewMode = 'normal';
      } else {
        this.placementViewMode = 'mini';
      }
    },

    appendEntity(record, entityType) {
      let entity = record[entityType];
      if (!entity || !this.filters[entityType + '_id']) {
        return;
      }
      for (let k in this.filters[entityType + '_id']) {
        if (+this.filters[entityType + '_id'].id === +entity.id) {
          return false;
        }
      }

      switch (entityType) {
        case 'placement':
        case 'offer':
          this.filters[entityType + '_id'].push({
            id: entity.id,
            name: `[${entity.id}] ${entity.name}`,
          });
          break;
        case 'advertiser':
        case 'publisher':
          this.filters[entityType + '_id'].push({
            v: entity.id,
            t: `[${entity.id}] ${entity.name}`,
          });
          break;
      }
      this.$forceUpdate();
    },

    entityClicked(e, i) {
      console.log('entityClicked');
      console.log(e, i);
    },

    // async cancelConversion(r){
    // 	if (confirm('Are you sure you want to cancel this conversion?')) {
    // 		let data = {
    // 			id: r.id
    // 		};
    // 		try {
    // 			let resp = await this.$ovReq.post('conversion/cancel', data);
    // 			r.report_time = resp.report_time;
    // 			r.status = resp.status;
    // 			r.cost = resp.cost;
    // 		} catch (e) {
    // 			console.error(e);
    // 		}
    // 	}
    // },

    // async report(r){
    // 	let confirmed = await this.$bvModal.msgBoxConfirm('Are you sure you want to report this conversion?');
    // 	if (confirmed) {
    // 		let data = {
    // 			id: r.id
    // 		};
    // 		try {
    // 			await this.$ovReq.post('conversion/report', data);
    // 			this.busy = false;
    // 			this.loadData();
    // 		} catch (e) {
    // 			console.error(e);
    // 		}
    // 	}
    // },

    async reject(r) {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to reject this conversion?',
      );
      if (confirmed) {
        let data = {
          id: r.id,
        };
        try {
          await this.$ovReq.post('conversion/reject', data);
          this.busy = false;
          this.loadData();
        } catch (e) {
          console.error(e);
        }
      }
    },

    async reconvert(r) {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to reconvert this conversion?',
      );
      if (!confirmed) {
        return;
      }

      const data = {
        id: r.id,
      };
      try {
        await this.$ovReq.post('conversion/reconvert', data);
        this.busy = false;
        this.loadData();
      } catch (e) {
        console.error(e);
      }
    },

    async unconvert(r) {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to abort this conversion?',
      );
      if (confirmed) {
        let data = {
          id: r.id,
        };
        try {
          await this.$ovReq.post('conversion/unconvert', data);
          this.busy = false;
          this.loadData();
        } catch (e) {
          console.error(e);
        }
      }
    },

    async loadData(doExport) {
      if (this.busy) {
        return;
      }
      let params = {
        conversion_type: 'advertiser',
        export: doExport ? 1 : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        advertiser_id: this.filters.advertiser_id.map((r) => r.v).join(','),
        publisher_id: this.filters.publisher_id.map((r) => r.v).join(','),
        add_headers: this.filters.add_headers,
        add_parameters: this.filters.add_parameters,
        adv_parameters: this.filters.adv_parameters,
        clicks: this.filters.clicks,

        offer_id: this.filters.offer_id.map((r) => r.id).join(','),
        placement_id: this.filters.placement_id.map((r) => r.id).join(','),
        report_status: this.filters.report_status,

        timezone: this.filters.datePicker.timezone,
        date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
      };

      this.busy = true;
      try {
        // let resp = await this.$ovReq.get('conversion/getList', {params});
        let resp = await this.$ovReq.post('conversion/getList', params);
        if (doExport) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.paginate.total = resp.total;
          this.additionalColumns = resp.columns;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
};
</script>
